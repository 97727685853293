/**
 * Utility class for handling Jig metadata operations, including sanitization and modal prompt checks.
 */

import { JigMetadata } from '@/store/modules/jig/types'
import { AppHelper } from '@/utils/app-helper'

export class JigHelpers {
  // eslint-disable-next-line lines-around-comment
  /**
   * Sanitizes and normalizes Jig metadata by cleaning up tags, categories, and project details.
   *
   * @param {JigMetadata} jigMetadata - The Jig metadata object to sanitize.
   * @param {string} jigTags - A comma-separated string of tags associated with the Jig.
   * @param {string | null} jigCategories - A comma-separated string of category names, or `null` if not applicable.
   *
   * **Functionality**:
   * - **Tags Sanitization**:
   *   - Trims whitespace and removes leading/trailing commas.
   *   - Splits the tags into an array and sanitizes each tag using `AppHelper.stringSanitiser`.
   *   - Sets `Tags` to an empty array if the input is an empty string.
   *
   * - **Categories Sanitization**:
   *   - Only processes if `jigCategories` is not `null`.
   *   - Trims, cleans commas, splits into an array, and sanitizes each category string.
   *
   * - **Other user editable JigMetadata fields sanitization**:
   *   - Applies the `AppHelper.stringSanitiser` to all other string value fields that users can edit in dashboard
   *
   * **Edge Cases Handled**:
   * - Empty tag or category strings are safely converted to empty arrays.
   * - Extra whitespace and non-printable characters are sanitized.
   */
  public static sanitiseJigData(jigMetadata: JigMetadata, jigTags: string, jigCategories: string | null) {
    const newTags = jigTags.trim().replace(/^,\s*|,\s*$/g, '')
    // There is extra rule to prevent non-printable character to be filled into the field, but we run sanitise function again for extra safety.
    jigMetadata.Tags = newTags === '' ? [] : newTags.split(/,\s*/g).map((tag: string) => AppHelper.stringSanitiser(tag))

    if (jigCategories !== null) {
      jigMetadata.Categories = jigCategories
        .trim()
        .replace(/^,\s*|,\s*$/g, '')
        .split(/,\s*/)
        .map((cat: string) => AppHelper.stringSanitiser(cat))
    }

    jigMetadata.ProjectName = AppHelper.stringSanitiser(jigMetadata.ProjectName)
    jigMetadata.ProjectDescription = AppHelper.stringSanitiser(jigMetadata.ProjectDescription)
  }

  /**
   * Evaluates a set of modal conditions and returns the reference of the first condition that requires a warning prompt.
   *
   * @param {{ [key: string]: boolean }} modalConditions - An object of that has modal $ref key value as key, and boolean as value.
   *
   * @returns {string | undefined} - The `modalRef` of the first condition that is `true`, or `undefined` if none match.
   */
  public static checkForWarningPrompt(modalConditions: { [key: string]: boolean }) {
    for (const modalRef in modalConditions) {
      if (modalConditions[modalRef]) {
        return modalRef
      }
    }
    return
  }
}
