
import HintTooltip from '@/components/helper/HintTooltip.vue'
import JigTextField from '@/components/input/JigTextField.vue'
import JigTooltip from '@/components/input/JigTooltip.vue'
import LabelWithHint from '@/components/input/LabelWithHint.vue'
import UsersPreviewList from '@/components/input/UsersPreviewList.vue'
import JigWarningModals from '@/components/jig/JigWarningModals.vue'
import TeamRights from '@/components/jig/TeamRights.vue'
import UploadThumbnail from '@/components/jig/UploadThumbnail.vue'
import MoreActions from '@/components/material/MoreActions.vue'
import JigActionModals from '@/components/modals/JigActionModals.vue'
import { ClosePromptFunction, ShowModalFunction, ShowPromptFunction } from '@/components/modals/type'
import { SetCopyableModelsFunction, TriggerSaveJigErrorFunction, UpdateCopyableByListFunction } from '@/components/share/type'
import IconDesktop from '@/components/svg/IconDesktop.vue'
import IconGlobe from '@/components/svg/IconGlobe.vue'
import IconLock from '@/components/svg/IconLock.vue'
import IconShield from '@/components/svg/IconShield.vue'
import IconTagPro from '@/components/svg/IconTagPro.vue'
import { JigConst, MueConst, SubscriptionConst } from '@/constants'
import FiltersMixin from '@/mixin/Filters'
import JigLock from '@/mixin/JigLock'
import JigPermissions from '@/mixin/JigPermissions'
import JigSharelink from '@/mixin/JigSharelink'
import { Tenant, TenantUser } from '@/store/modules/app/types'
import {
  JigCategoryOption,
  JigMetadata,
  JigShareURLData,
  JigVisibilityEventData,
  JigVisibilityListOption,
  JigVisibilityOption,
  Permission,
} from '@/store/modules/jig/types'
import { Namespace } from '@/store/types'
import { AppHelper } from '@/utils/app-helper'
import { eventBus } from '@/utils/eventBus'
import { ValidationRules } from '@/utils/input-validation'
import { JigHelpers } from '@/utils/jig-helpers'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { segmentEventTracking } from '@/utils/tracking'
import { mixins } from 'vue-class-component'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'

@Component({
  components: {
    'hint-tooltip': HintTooltip,
    'jig-action-modals': JigActionModals,
    'jig-text-field': JigTextField,
    'jig-tooltip': JigTooltip,
    'label-with-hint': LabelWithHint,
    'more-actions': MoreActions,
    'users-preview-list': UsersPreviewList,
    'team-rights': TeamRights,
    'upload-thumbnail-modal': UploadThumbnail,
    'jig-warning-modals': JigWarningModals,
    'icon-desktop': IconDesktop,
    'icon-globe': IconGlobe,
    'icon-lock': IconLock,
    'icon-shield': IconShield,
    'icon-tag-pro': IconTagPro,
  },
})
export default class JigDetailVue extends mixins(FiltersMixin, JigLock, JigPermissions, JigSharelink) {
  @State('jigDetailPermissions', { namespace: Namespace.Jig })
  public jigDetailPermissions!: Permission[]
  @State('jigCategoryOptions', { namespace: Namespace.Jig })
  public jigCategoryOptions!: JigCategoryOption[]
  @State('initializing', { namespace: Namespace.Utils })
  public initializing!: boolean

  @Getter('isFreeTier', { namespace: Namespace.App })
  public isFreeTier!: boolean
  @Getter('isOrgTier', { namespace: Namespace.App })
  public isOrgTier!: boolean
  @Getter('isPreparingApp', { namespace: Namespace.Utils })
  public isPreparingApp!: boolean

  @Action('executeStatisticReport', { namespace: Namespace.Jig }) public executeStatisticReport: any
  @Action('loadJigMetadata', { namespace: Namespace.Jig }) public loadJigMetadata: any
  @Action('loadJigPermissions', { namespace: Namespace.Jig }) public loadJigPermissions: any
  @Action('clearJigShareLink', { namespace: Namespace.Jig }) public clearJigShareLink: any
  @Action('updateJigMetadata', { namespace: Namespace.Jig }) public updateJigMetadata: any
  @Action('updateJigVisibility', { namespace: Namespace.Jig }) public updateJigVisibility: any

  public $refs!: Vue['$refs'] & {
    jigActionModals: JigActionModals
    jigTagsList: typeof JigTextField
    jigNameTip: HintTooltip
    teamRights: TeamRights
    uploadThumbnailModal: UploadThumbnail
    jigWarningModals: JigWarningModals
  }

  private nameRules: any = ValidationRules.RequiredName

  // Published Jig is a Jig with visibility value `public`. To distinguish from `public sharing` Jig, we use `publish` in naming instead.
  private static publishVisibility: JigVisibilityOption = {
    icon: 'icon-desktop',
    text: JigConst.jigVisibilitiesStaff[JigConst.JigVisibilities.Public],
  }
  // Default visibility dropdown for all non-SuperUser/non-JigStaff users should contain just `link`, `link-password` and `private`.
  private visibilityItems: {
    [K in JigConst.JigVisibilities]?: JigVisibilityOption
  } = {
    [JigConst.JigVisibilities.Link]: {
      icon: 'icon-globe',
      text: JigConst.jigVisibilities[JigConst.JigVisibilities.Link],
    },
    [JigConst.JigVisibilities.Private]: {
      icon: 'icon-shield',
      text: JigConst.jigVisibilities[JigConst.JigVisibilities.Private],
    },
    [JigConst.JigVisibilities.Password]: {
      icon: 'icon-lock',
      text: JigConst.jigVisibilities[JigConst.JigVisibilities.Password],
    },
  }
  private categoryItems: any = []
  private isFormValid: boolean = false
  private promptPending: { [key: string]: boolean } = {
    // Flag var for when Jig visibility is changed to `public`
    publishingJig: false,
    // Flag var for when user toggles `Public sharing` switch
    isPublicSharing: false,
    // Flag var for when Jig visibility is changed from `link` to `private`
    linkJigToPrivate: false,
    // Flag var for when Jig visibility is changed from `link` to `link-password`
    linkJigToPassword: false,
    // If Jig password regenerate confirm is required
    jigPasswordRegenerate: false,
  }
  private usersWithAccess: TenantUser[] = []
  private previewLength: number = 4
  private visibilityOnLoad: string = ''
  private permissionsDirty: boolean = false
  private publicSharing: boolean = false
  private isCopyableByOthers: boolean = false
  private copyableBy: string = ''
  private pullApartAllowed: boolean = false
  private isLoadingJigDetails: boolean = true
  private jigCategoryRules: any = {
    select: [this.validateCategories.bind(this)],
  }
  private jigTags: string = ''
  private jigCategories: string = ''
  private jigVisibility: JigVisibilityListOption | null = null

  // get jig view data after the jig details have been loaded.
  @Watch('jigMetadata')
  private async onJigDetailChanged() {
    if (typeof this.jigMetadata === 'undefined') {
      return
    }

    this.pullApartAllowed =
      this.jigMetadata.Features != null && this.jigMetadata.Features.options[SubscriptionConst.Features.AVPFeaturePullApartKey].enabled

    this.updateJigDataModels()
  }

  @Watch('myTenant')
  private onMyTenantChanged(value: Tenant) {
    this.myTenantID = value.ID
    this.updateMyTenantIndex()
    this.$callRefMethod<UpdateCopyableByListFunction>('teamRights', 'UpdateCopyableByList')
  }

  @Watch('initializing')
  private onInitializingChanged(value: boolean, oldValue: boolean) {
    if (!value) {
      this.initializeData()
    }
  }

  // Sometimes weirdly onChange event of v-switch won't be triggered successfully when v-model value is manually updated.
  // Change to watch event so it's alwasy triggered.
  @Watch('publicSharing')
  private onPublicSharingChanged(value: boolean) {
    this.promptPending.isPublicSharing = true
  }

  private get ownerName(): string {
    const owner = this.tenantUsers.find((u: TenantUser) => u.Uid === this.jigMetadata.Uid)

    return owner != null ? owner.name || owner.username : ''
  }

  // Only count those with edit permission (inc. owner, editor, team manager/admin)
  private get individualPermDetails(): string {
    let editor: number = 0

    this.jigDetailPermissions.forEach((perm: Permission) => {
      if (perm.CRUD && perm.CRUD.U && perm.AudID !== this.jigMetadata.Uid) {
        // Can edit and not Jig owner
        editor += 1
      }
    })

    return `${editor} editor${editor === 1 ? '' : 's'}`
  }

  private get tenantUsersPreview(): TenantUser[] {
    const startIndex = this.usersWithAccess.length < this.previewLength ? this.usersWithAccess.length : this.previewLength
    const selectedUserUIDs = this.usersWithAccess.map((u: TenantUser) => u.Uid)

    return this.usersWithAccess
      .slice(0, startIndex)
      .concat(this.tenantUsers.filter((u: TenantUser) => !selectedUserUIDs.includes(u.Uid)).slice(0, this.previewLength - startIndex))
  }

  private get showJigActions(): boolean {
    return this.isOwner() || this.canEditOrDeleteJig() || this.teamCanReshare
  }

  private get isPullApartConfigAllowed(): boolean {
    return this.jigMetadata.FormatVersionAtCreation > 8.8
  }

  private get isSuperUserOrJigStaff(): boolean {
    return TenantHelpers.IsSuperUserOrJigStaff()
  }

  private get publicSharingSwitchDisabled(): boolean {
    return !this.canEditOrDeleteJig() || this.isFreeTier
  }

  private get jigDisplayName(): string {
    if (this.isPreparingApp || this.isProcessing || this.jigMetadata == null) {
      return ''
    }

    if (!this.canEditOrDeleteJig() && !this.isLoadingJigDetails) {
      return this.jigMetadata.ProjectName + ' (view only)'
    }
    return this.jigMetadata.ProjectName
  }

  private get jigVisibilityOptions(): JigVisibilityListOption[] {
    return Object.keys(this.visibilityItems)
      .map((key) => {
        const typedKey = key as JigConst.JigVisibilities // Cast to JigConst.JigVisibilities
        const item = this.visibilityItems[typedKey]

        if (item != null) {
          return {
            ...item,
            value: typedKey,
            disabled:
              (this.isFreeTier && typedKey !== JigConst.JigVisibilities.Link) ||
              (!this.isOrgTier && typedKey === JigConst.JigVisibilities.Password),
          }
        } else {
          return null
        }
      })
      .filter((option): option is JigVisibilityListOption => option != null)
  }

  private get warningModalsConditions(): { [key: string]: boolean } {
    const conditions = { ...this.promptPending }
    delete conditions.isPublicSharing

    return conditions
  }

  protected created() {
    this.jigVisibility = this.jigVisibilityOptions[0]
  }

  protected mounted() {
    if (!this.initializing) {
      this.initializeData()
    }

    this.$on(this.jigEvents.onUpdatingJigVisibility, (payload: JigVisibilityEventData) => {
      if (payload.teamSharePermissions != null) {
        this.originalJigTeamSharePermissions = this.jigMetadata.Tenants ? [...this.jigMetadata.Tenants] : undefined
        this.isTeamShareConfigChange = payload.teamSharePermissions != null

        this.craftJigTeamSharingData(payload)
      }
    })
  }

  private async initializeData() {
    if (this.isSuperUserOrJigStaff) {
      Vue.set(this.visibilityItems, JigConst.JigVisibilities.Public, JigDetailVue.publishVisibility)
    } else {
      Vue.delete(this.visibilityItems, JigConst.JigVisibilities.Public)
    }

    await this.loadJigAndReset()
    this.$callRefMethod<UpdateCopyableByListFunction>('teamRights', 'UpdateCopyableByList')
    this.$callRefMethod<SetCopyableModelsFunction>('teamRights', 'SetCopyableModels', {
      isCopyableByOthers: this.isCopyableByOthers,
      copyableBy: this.copyableBy,
    })
  }

  private updateJigDataModels() {
    this.jigTags = this.jigMetadata.Tags.join(', ')
    this.jigVisibility = this.jigVisibilityOptions.find(
      (v: JigVisibilityListOption) => v.value === this.jigMetadata.ProjectVisibility
    ) as JigVisibilityListOption

    if (!this.isSuperUserOrJigStaff) {
      this.jigCategories = this.jigMetadata.Categories.join(', ')
    }
  }

  private validateCategories(v: string[]) {
    const standardCat = v.filter((cat: string) => this.jigCategoryOptions.find((op: any) => op.value === cat) != null)

    return (standardCat && standardCat.length <= 1) || `Please update to only one category (or none)`
  }

  private async loadJigAndReset() {
    this.isLoadingJigDetails = true

    await this.loadJigMetadata({
      jigId: this.$route.params.id,
    })
    await this.loadJigPermissions({
      jigId: this.$route.params.id,
    })

    this.usersWithAccess = this.tenantUsers.filter(
      (user: TenantUser) =>
        this.jigDetailPermissions.find((perm: Permission) => perm.AudID === user.Uid && perm.CRUD && (perm.CRUD.U || perm.CRUD.R)) != null
    )

    this.publicSharing =
      this.jigMetadata.ProjectVisibility !== JigConst.JigVisibilities.Private &&
      this.jigMetadata.ProjectVisibility !== JigConst.JigVisibilities.Password
    this.visibilityOnLoad = this.jigMetadata.ProjectVisibility
    this.addNonStandardCategoriesWithWarning(this.jigMetadata.Categories)
    this.myTenantID = this.myTenant.ID
    this.updateMyTenantIndex()
    this.pullApartAllowed =
      this.jigMetadata.Features != null && this.jigMetadata.Features.options[SubscriptionConst.Features.AVPFeaturePullApartKey].enabled

    if (this.jigMetadata.Tenants) {
      // When the permissions are removed completely, Permissions might return null so we need to assign empty array as fallback
      this.updateTeamSharePermissions(
        this.jigMetadata.Tenants[this.myTenantIndex] && this.jigMetadata.Tenants[this.myTenantIndex].Permissions
          ? this.jigMetadata.Tenants[this.myTenantIndex].Permissions
          : []
      )

      this.teamSharePermModel = this.teamSharePermissions
      this.teamSharePermModelOldVal = this.teamSharePermissions
    }

    if (this.jigMetadata.CopyableBy === JigConst.JigCopyable.Owner) {
      this.isCopyableByOthers = false
      this.copyableBy = JigConst.JigCopyable.Anyone // default
    } else if (this.jigMetadata.CopyableBy) {
      this.isCopyableByOthers = true
      this.copyableBy = this.jigMetadata.CopyableBy
    }

    this.updateJigDataModels()

    this.isLoadingJigDetails = false
  }

  private addNonStandardCategoriesWithWarning(categories: string[]): any {
    this.categoryItems = this.jigCategoryOptions
    const nonStandardCategories = categories.filter((x: string) => !this.jigCategoryOptions.some((y: JigCategoryOption) => y.value === x))
    this.categoryItems = this.categoryItems.concat(
      nonStandardCategories.map((z: string) => ({
        text: `${z} (not standard)`,
        value: z,
      }))
    )
  }

  private showDeleteJigPrompt() {
    eventBus.$emit(this.rootJigEvents.onJigDelete, {
      jigId: this.jigMetadata.Id,
      jigName: this.jigMetadata.ProjectName,
      jigFormatVersion: this.jigMetadata.FormatVersion,
    })
  }

  private async showCopyJigPrompt() {
    eventBus.$emit(this.rootJigEvents.showCopyJigPrompt, {
      jigId: this.jigMetadata.Id,
      jigName: this.jigMetadata.ProjectName,
      visibility: this.jigMetadata.ProjectVisibility,
    })
  }

  private onTeamShareModelsChanged(value: any) {
    // Check relevant logic and update accordingly, for example reshare can only be enabled when view is enabled.
    this.onTeamShareChanged(value)
  }

  private onJigVisibilityChange(option: any) {
    this.publicSharing = option.value !== JigConst.JigVisibilities.Private && option.value !== JigConst.JigVisibilities.Password
    this.promptPending.publishingJig = false
    this.promptPending.linkJigToPrivate = false
    this.promptPending.linkJigToPassword = false
    this.promptPending.jigPasswordRegenerate = false

    if (this.visibilityOnLoad !== JigConst.JigVisibilities.Public) {
      if (option.value === JigConst.JigVisibilities.Public) {
        // Visibility value `public` is selected from visibility dropdown
        this.promptPending.publishingJig = true
      } else {
        // Visibility value `link` / `link-password` / `private` is selected
        this.promptPending.publishingJig = false

        if (this.visibilityOnLoad === JigConst.JigVisibilities.Link && option.value === JigConst.JigVisibilities.Private) {
          // Jig visibility on page load is `link` and `private` is selected
          this.promptPending.linkJigToPrivate = true
        } else if (this.visibilityOnLoad === JigConst.JigVisibilities.Link && option.value === JigConst.JigVisibilities.Password) {
          // Jig visibility on page load is `link` and `link-password` is selected
          this.promptPending.linkJigToPassword = true
        } else if (
          this.visibilityOnLoad === JigConst.JigVisibilities.Private &&
          option.value === JigConst.JigVisibilities.Password &&
          this.jigMetadata.Password
        ) {
          // Jig visibility on page load is `private` and `link-password` is selected
          this.promptPending.jigPasswordRegenerate = true
        }
      }
    } else if (option.value === JigConst.JigVisibilities.Private) {
      // Jig visibility on page load is `public` and `private` is selected
      this.promptPending.linkJigToPrivate = true
    } else if (option.value === JigConst.JigVisibilities.Password) {
      // Jig visibility on page load is `public` and `link-password` is selected
      this.promptPending.jigPasswordRegenerate = true
    }
  }

  private onCopyableByChange(copyableBy: string) {
    this.copyableBy = copyableBy
  }

  private onCopyableByOthersChange(isCopyableByOthers: boolean) {
    this.isCopyableByOthers = isCopyableByOthers
  }

  private checkingPublicSharingPublish(): boolean {
    if (this.jigVisibility == null) {
      return false
    }

    if (
      (this.visibilityOnLoad === JigConst.JigVisibilities.Private || this.visibilityOnLoad === JigConst.JigVisibilities.Password) &&
      this.publicSharing &&
      this.jigVisibility.value !== JigConst.JigVisibilities.Public
    ) {
      // Jig visibility is `private` or `link-passwrod` on page load and we now turned on `Public sharing` switch.
      // Exclude the situation when JigStaff/SuperUser has set visibility value to `public`
      this.$callRefMethod<ShowPromptFunction>('jigWarningModals', 'ShowPrompt', 'privateJigToLinkPromptYesNo')
      return true
    } else if (
      this.visibilityOnLoad === JigConst.JigVisibilities.Link &&
      !this.publicSharing &&
      this.jigVisibility.value !== JigConst.JigVisibilities.Password
    ) {
      // Jig visibility is `link` on page load and we selected `private` visibility, or we now turned off `Public sharing` switch.
      this.$callRefMethod<ShowPromptFunction>('jigWarningModals', 'ShowPrompt', 'publicJigToPrivatePromptYesNo')
      return true
    }
    // All other situations, no warning prompt is required. For example `link-password` to `private`.
    return false
  }

  private async onWarningPrompConfirmed(modalRefs: string[], customFunc: () => void, regeneratePassword?: boolean) {
    this.isProcessingPromptYesNo = true
    modalRefs.forEach((modalRef: string) => this.$callRefMethod<ClosePromptFunction>('jigWarningModals', 'ClosePrompt', modalRef))

    customFunc()

    await this.saveChanges(regeneratePassword)
    this.isProcessingPromptYesNo = false
  }

  private async publishCheckedThenSaveChanges() {
    await this.onWarningPrompConfirmed(['publishingJigPromptYesNo'], () => {
      this.promptPending.publishingJig = false
    })
  }

  private async changeJigToPrivateConfirmed() {
    await this.onWarningPrompConfirmed(['publicJigToPrivatePromptYesNo', 'linkJigToPrivatePromptYesNo'], () => {
      this.publicSharing = false
      this.jigVisibility = this.jigVisibilityOptions.find(
        (option: JigVisibilityListOption) => option.value === JigConst.JigVisibilities.Private
      ) as JigVisibilityListOption
      this.promptPending.linkJigToPrivate = false
    })
  }

  private async regeneratePasswordConfirmed(regeneratePassword: boolean) {
    await this.onWarningPrompConfirmed(
      ['jigPasswordRegeneratePromptYesNo', 'linkJigToPasswordPromptYesNo'],
      () => {
        this.promptPending.linkJigToPassword = false
        this.jigVisibility = this.jigVisibilityOptions.find(
          (option: JigVisibilityListOption) => option.value === JigConst.JigVisibilities.Password
        ) as JigVisibilityListOption
        this.promptPending.jigPasswordRegenerate = false
      },
      regeneratePassword
    )
  }

  private async requestChangeJigToLink() {
    await this.onWarningPrompConfirmed(['privateJigToLinkPromptYesNo'], () => {
      this.publicSharing = true
      this.jigVisibility = this.jigVisibilityOptions.find(
        (option: JigVisibilityListOption) => option.value === JigConst.JigVisibilities.Link
      ) as JigVisibilityListOption
    })
  }

  private async saveChanges(regeneratePassword: boolean | null | undefined = null) {
    this.isProcessing = true

    const newJigMetadata = AppHelper.deepCopyNestedJsonSafeObject(this.jigMetadata)
    const queryParams: any = {}
    let telemetryRequired: boolean = false

    // Get the key of any visibility change relevant warning prompt that is required to be shown
    const warningModalRef = JigHelpers.checkForWarningPrompt(this.warningModalsConditions)
    if (warningModalRef != null) {
      this.$callRefMethod<ShowPromptFunction>('jigWarningModals', 'ShowPrompt', `${warningModalRef}PromptYesNo`)
      return
    }

    if (!this.publicSharingSwitchDisabled && this.promptPending.isPublicSharing) {
      this.promptPending.isPublicSharing = false
      if (this.checkingPublicSharingPublish()) {
        return
      }
    }

    const isLockObtained = await this.RequestNewLock()
    if (!isLockObtained) {
      return
    }

    JigHelpers.sanitiseJigData(newJigMetadata, this.jigTags, this.isSuperUserOrJigStaff ? null : this.jigCategories)

    if (this.jigVisibility != null) {
      newJigMetadata.ProjectVisibility =
        this.jigVisibility.value === JigConst.JigVisibilities.Link && !this.publicSharing
          ? JigConst.JigVisibilities.Private
          : this.jigVisibility.value
    }

    if (this.isOwner() || this.canEditOrDeleteJig()) {
      if (!newJigMetadata.Tenants) {
        newJigMetadata.Tenants = []
      }

      if (this.isCopyableByOthers) {
        newJigMetadata.CopyableBy = this.copyableBy
      } else {
        newJigMetadata.CopyableBy = JigConst.JigCopyable.Owner
      }
    }

    if (this.visibilityOnLoad !== newJigMetadata.ProjectVisibility) {
      // If visibility changes we need to clear our local cache of the branch link because
      // it may change how the link is generated.
      this.clearJigShareLink({
        jigId: newJigMetadata.Id,
        requestInFlight: false,
        shareURL: '',
      } as JigShareURLData)

      if (newJigMetadata.ProjectVisibility === JigConst.JigVisibilities.Password) {
        queryParams.regenPassword = regeneratePassword
      } else {
        delete queryParams.regenPassword
      }
    }

    if (
      (newJigMetadata.Features == null && this.pullApartAllowed) ||
      (newJigMetadata.Features &&
        this.pullApartAllowed !== newJigMetadata.Features.options[SubscriptionConst.Features.AVPFeaturePullApartKey].enabled)
    ) {
      telemetryRequired = true

      newJigMetadata.Features.options[SubscriptionConst.Features.AVPFeaturePullApartKey].enabled = this.pullApartAllowed
    }

    const metaUpdateResponse = await this.updateJigMetadata({
      jigMetadata: newJigMetadata,
      queryParams,
    })

    await this.releaseLock(this.lockPayload)

    if (metaUpdateResponse.isSuccess) {
      if (telemetryRequired) {
        segmentEventTracking('PullApartSet', {
          ...this.eventTracking(),
          origin: 'Dashboard',
          jigId: newJigMetadata.Id,
          jigName: newJigMetadata.ProjectName,
          tenantName: this.myTenant.Name,
          tenantId: this.myTenantID,
          state: this.pullApartAllowed,
        })
      }
      if (this.isTeamShareConfigChange) {
        this.teamShareEventTrack(this.currentPermission, this.newTeamPermissions)
      }
      this.resetTeamSharePermissions()
      await this.loadJigAndReset()
    } else {
      if (metaUpdateResponse.status === MueConst.nonSystemErrors.unprocessableEntity.status) {
        this.$callRefMethod<TriggerSaveJigErrorFunction>(
          'jigWarningModals',
          'TriggerSaveJigError',
          MueConst.nonSystemErrors.unprocessableEntity.copy
        )
      }

      if (this.isTeamShareConfigChange) {
        // reset team share
        this.resetTeamSharePermissions()
      }
    }

    this.isTeamShareConfigChange = false
    this.resetSavingStatus()
  }

  private resetSavingStatus() {
    this.isProcessing = false
  }

  private async transferJigOwnership(payload: JigMetadata) {
    eventBus.$emit(this.rootJigEvents.onJigTransfer, payload)
  }

  private openImageUploadModal() {
    this.$callRefMethod<ShowModalFunction>('uploadThumbnailModal', 'ShowModal')
  }

  private async onLoadResetRequested(doneCallback: () => void) {
    await this.loadJigAndReset()
    doneCallback() // signal back to thumbnail upload component that it's done
  }

  private async onDataReloadRequested(doneCallback: () => void) {
    await this.reloadData()
    doneCallback() // signal back to thumbnail upload component that it's done
  }

  private onUpdateJigShareConfig(payload: any) {
    this.permissionsDirty = payload.permissionsDirty
  }

  private async showJigPermissionsPrompt() {
    this.$refs.jigActionModals.ShowJigPermissionsPromptOnly([this.jigMetadata])
  }

  private onSharePromptClose(payload: any) {
    // If any request actually happened in sharePrompt, reset jig data regardless success or failure, so we can get the latest Jig data.
    if (payload.updateResults != null) {
      this.permissionsDirty = true
    }

    this.loadJigIfPermissionsDirty()
  }

  private async reloadData() {
    this.permissionsDirty = true
    await this.loadJigIfPermissionsDirty()
  }

  private async loadJigIfPermissionsDirty() {
    if (this.permissionsDirty) {
      this.permissionsDirty = false
      await this.loadJigAndReset()
    }
  }

  private onCopyShareableLink(payload: any) {
    eventBus.$emit(this.rootJigEvents.onCopyShareableLink, {
      visibility: this.jigMetadata.ProjectVisibility,
      data: {
        deeplink: this.jigMetadata.DeeplinkURL,
        showSuccessMessage: true,
        activity: 'JigSharedViaShareableLink',
        event: payload.event,
      },
    })
  }

  private goBack() {
    if ((window && window.history.length > 1) || (history && history.length > 1)) {
      this.$router.back()
    } else {
      this.$router.push('/my-jigs')
    }
  }
}
